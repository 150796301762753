import React from "react";
import { connect } from "react-redux";
import { ReactSVG } from "react-svg";
import * as d3 from "d3";
import ReactTooltip from "react-tooltip";
import * as selectors from "../../selectors";
import { withTranslation } from "react-i18next";

class CardStack extends React.Component {
  constructor() {
    super();
    this.refs = {};
    this.refCardStack = React.createRef();
    this.refCardStackContent = React.createRef();
    this.state = {
      tooltip: "Copy incident information",
      shareTooltip: "Copy Link to Share Directly to this Incident",
      infoTooltip: "",
    };
  }

  componentDidUpdate() {
    const isNarrative = !!this.props.narrative;

    if (isNarrative) {
      this.scrollToCard();
    }
  }

  scrollToCard() {
    const duration = 500;
    const element = this.refCardStack.current;
    const cardScroll =
      this.refs[this.props.narrative.current].current.offsetTop;

    const start = element.scrollTop;
    const change = cardScroll - start;
    let currentTime = 0;
    const increment = 20;

    // t = current time
    // b = start value
    // c = change in value
    // d = duration
    Math.easeInOutQuad = function (t, b, c, d) {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t -= 1;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    const animateScroll = function () {
      currentTime += increment;
      const val = Math.easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = val;
      if (currentTime < duration) setTimeout(animateScroll, increment);
    };
    animateScroll();
  }

  renderCards(events, selections) {
    // if no selections provided, select all
    if (!selections) {
      selections = events.map((e) => true);
    }
    this.refs = [];
    const { t } = this.props;
    const parseTime = d3.timeParse("%m/%d/%Y");
    const formatDate = d3.timeFormat("%d %B %Y");

    const copyTextToClipboard = async (text) => {
      if ("clipboard" in navigator) {
        return await navigator.clipboard.writeText(text);
      } else {
        return document.execCommand("copy", true, text);
      }
    };
    // const generateTemplate =
    //   generateCardLayout[this.props.cardUI.layout.template];

    return events.map((event, idx) => {
      const thisRef = React.createRef();
      this.refs[idx] = thisRef;

      return (
        <div
          style={{
            background: "#dddddd",
            padding: "1rem",
            marginBottom: "0.2em",
          }}
          key={idx}
          onMouseEnter={() =>
            this.setState({
              tooltip: t("card_copy"),
              shareTooltip: t("card_share"),
              infoTooltip: t("card_info"),
            })
          }
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              alignItems: "flex-start",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                margin: "0.2rem",
              }}
            >
              <h5
                style={{
                  color: "#767676",
                  textTransform: "uppercase",
                  marginBottom: "0em",
                }}
              >
                {t("card_incident_date")}
              </h5>
              <span style={{ color: "black", fontSize: "0.85em" }}>
                {formatDate(parseTime(event.date))}
              </span>
            </div>
            <div style={{ margin: "0.2rem" }}>
              <h5
                style={{
                  color: "#767676",
                  textTransform: "uppercase",
                  marginBottom: "0em",
                }}
              >
                {t("card_location")}
              </h5>
              <span style={{ color: "black", fontSize: "0.85em" }}>
                {event.location}
              </span>
            </div>
          </div>
          <h5
            style={{
              color: "#767676",
              textTransform: "uppercase",
              marginBottom: "0em",
            }}
          >
            {t("card_summary")}
          </h5>
          <span style={{ color: "black", fontSize: "0.85em" }}>
            {event.description}
          </span>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",
              alignItems: "flex-start",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                margin: "0.2rem",
              }}
            >
              <h5
                style={{
                  color: "#767676",
                  textTransform: "uppercase",
                  marginBottom: "0em",
                }}
              >
                {t("card_ref")}
              </h5>
              <span style={{ color: "black", fontSize: "0.85em" }}>
                {event.ref}
              </span>
            </div>
            <div style={{ margin: "0.2rem" }}>
              <h5
                style={{
                  color: "#767676",
                  textTransform: "uppercase",
                  marginBottom: "0em",
                }}
              >
                {t("card_type")}
              </h5>
              <span style={{ color: "black", fontSize: "0.85em" }}>
                {event.type}
              </span>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-end",
              alignItems: "flex-start",
              flexDirection: "row",
            }}
          >
            <div
              style={{
                margin: "0.2rem",
                zIndex: 2000,
              }}
            >
              <ReactTooltip id="sharetoolTip" place="top" effect="solid">
                {this.state.shareTooltip}
              </ReactTooltip>
              <ReactSVG
                src="SHARE-1.svg"
                style={{
                  width: "1.5rem",
                  padding: "0.2rem",
                  cursor: "pointer",
                }}
                onClick={() => {
                  copyTextToClipboard(
                    `${window.location.href.split("?")[0]}?key=${event.key}`
                  );
                  this.setState({ shareTooltip: "Copied to clipboard" });
                }}
                data-tip
                data-for="sharetoolTip"
              />

              {/* <button
                // onClick={() => {
                //   copyTextToClipboard(
                //     `${window.location.href.split("?")[0]}?key=${event.key}`
                //   );
                // }}
                style={{
                  width: "1.5rem",
                  padding: "0.2rem",
                  cursor: "pointer",
                }}
                onClick={() => {
                  copyTextToClipboard(
                    `${window.location.href.split("?")[0]}?key=${event.key}`
                  );
                  this.setState({ shareTooltip: t("card_copied") });
                }}
                data-tip
                data-for="sharetoolTip"
              /> */}
            </div>
            <div style={{ margin: "0.2rem", zIndex: 2000 }}>
              <ReactTooltip id="registerTip" place="top" effect="solid">
                {this.state.tooltip}
              </ReactTooltip>
              <ReactSVG
                src="copy.svg"
                style={{
                  width: "1.5rem",
                  padding: "0.2rem",
                  cursor: "pointer",
                }}
                onClick={() => {
                  copyTextToClipboard(
                    `${t("card_incident_date")}: ${event.date} \r\n${t(
                      "card_location"
                    )}: ${event.location} \r\n${t("card_summary")}: ${
                      event.description
                    } \r\n${t("card_ref")}: ${event.ref} \r\n${t(
                      "card_type"
                    )}: ${event.type}`
                  );
                  this.setState({ tooltip: t("card_copied") });
                }}
                data-tip
                data-for="registerTip"
              />
            </div>
            <div style={{ margin: "0.2rem", zIndex: 2000 }}>
              <ReactTooltip id="infoTip" place="top" effect="solid">
                {this.state.infoTooltip}
              </ReactTooltip>
              <ReactSVG
                src="Info-02-02.svg"
                style={{
                  width: "1.5rem",
                  padding: "0.2rem",
                  cursor: "pointer",
                }}
                data-tip
                data-for="infoTip"
              />
            </div>
          </div>
        </div>
      );
    });
  }

  renderSelectedCards() {
    const { selected } = this.props;

    if (selected.length > 0) {
      return this.renderCards(selected);
    }
    return null;
  }

  renderNarrativeCards() {
    const { narrative } = this.props;
    const showing = narrative.steps;

    const selections = showing.map((_, idx) => idx === narrative.current);

    return this.renderCards(showing, selections);
  }

  renderCardStackHeader() {
    // eslint-disable-next-line
    const { t, i18n } = this.props;
    // const headerLang = copy[this.props.language].cardstack.header;
    const headerLang = t("cardstack_header");
    return (
      <div
        id="card-stack-header"
        className="card-stack-header"
        onClick={() => this.props.onToggleCardstack()}
      >
        <button className="side-menu-burg is-active">
          <span />
        </button>
        <p className="header-copy top">
          {`${this.props.selected.length} ${headerLang}`}
        </p>
      </div>
    );
  }

  renderCardStackContent() {
    return (
      <div id="card-stack-content" className="card-stack-content">
        <ul>{this.renderSelectedCards()}</ul>
      </div>
    );
  }

  renderNarrativeContent() {
    return (
      <div
        id="card-stack-content"
        className="card-stack-content"
        ref={this.refCardStackContent}
      >
        <ul>{this.renderNarrativeCards()}</ul>
      </div>
    );
  }

  render() {
    const { isCardstack, selected, narrative, timelineDims } = this.props;
    // TODO: make '237px', which is the narrative header, less hard-coded
    const height = `calc(100% - 237px - ${timelineDims.height}px)`;
    if (selected.length > 0) {
      if (!narrative) {
        return (
          <div
            id="card-stack"
            className={`card-stack
            ${isCardstack ? "" : " folded"}`}
          >
            {this.renderCardStackHeader()}
            {this.renderCardStackContent()}
          </div>
        );
      } else {
        return (
          <div
            id="card-stack"
            ref={this.refCardStack}
            className={`card-stack narrative-mode
            ${isCardstack ? "" : " folded"}`}
            style={{ height }}
          >
            {this.renderNarrativeContent()}
          </div>
        );
      }
    }

    return <div />;
  }
}

function mapStateToProps(state) {
  return {
    narrative: selectors.selectActiveNarrative(state),
    selected: selectors.selectSelected(state),
    sourceError: state.app.errors.source,
    language: state.app.language,
    isCardstack: state.app.flags.isCardstack,
    isLoading: state.app.flags.isFetchingSources,
    cardUI: state.ui.card,
    colors: state.ui.coloring.colors,
    coloringSet: state.app.associations.coloringSet,
    features: state.features,
  };
}

export default withTranslation()(connect(mapStateToProps)(CardStack));
