import { mergeDeepLeft } from "ramda";
import chroma from "chroma-js";
import global from "../common/global";
import copy from "../common/data/copy.json";
import { language } from "../common/utilities";
import { DEFAULT_TAB_ICONS } from "../common/constants";

const gcolors = chroma
  .scale([
    "#e52b50",
    "#ffbf00",
    "#ff033e",
    "#5d8aa8",
    "#f0f8ff",
    "#e32636",
    "#efdecd",
    "#9966cc",
    "#a4c639",
    "#f2f3f4",
    "#cd9575",
    "#915c83",
    "#faebd7",
    "#008000",
    "#8db600",
    "#fbceb1",
    "#00ffff",
    "#7fffd4",
    "#4b5320",
    "#e9d66b",
    "#b2beb5",
    "#87a96b",
    "#ff9966",
    "#a52a2a",
    "#fdee00",
    "#6e7f80",
    "#ff2052",
    "#007fff",
    "#f0ffff",
    "#89cff0",
    "#a1caf1",
    "#f4c2c2",
    "#21abcd",
    "#fae7b5",
    "#ffe135",
    "#848482",
    "#98777b",
    "#bcd4e6",
    "#9f8170",
    "#f5f5dc",
    "#ffe4c4",
    "#3d2b1f",
    "#fe6f5e",
    "#000000",
    "#ffebcd",
    "#318ce7",
    "#ace5ee",
    "#faf0be",
    "#0000ff",
    "#a2a2d0",
    "#6699cc",
    "#0d98ba",
    "#8a2be2",
    "#8a2be2",
    "#de5d83",
    "#79443b",
    "#0095b6",
    "#e3dac9",
    "#cc0000",
    "#006a4e",
    "#873260",
    "#0070ff",
    "#b5a642",
    "#cb4154",
    "#1dacd6",
    "#66ff00",
    "#bf94e4",
    "#c32148",
    "#ff007f",
    "#08e8de",
    "#d19fe8",
    "#f4bbff",
    "#ff55a3",
    "#fb607f",
    "#004225",
    "#cd7f32",
    "#a52a2a",
    "#ffc1cc",
    "#e7feff",
    "#f0dc82",
    "#480607",
    "#800020",
    "#deb887",
    "#cc5500",
    "#e97451",
    "#8a3324",
    "#bd33a4",
    "#702963",
    "#007aa5",
    "#e03c31",
    "#536872",
    "#5f9ea0",
    "#91a3b0",
    "#006b3c",
    "#ed872d",
    "#e30022",
    "#fff600",
    "#a67b5b",
    "#4b3621",
    "#1e4d2b",
    "#a3c1ad",
    "#c19a6b",
    "#78866b",
    "#ffff99",
    "#ffef00",
    "#ff0800",
    "#e4717a",
    "#00bfff",
    "#592720",
    "#c41e3a",
    "#00cc99",
    "#ff0040",
    "#eb4c42",
    "#ff0038",
    "#ffa6c9",
    "#b31b1b",
    "#99badd",
    "#ed9121",
    "#ace1af",
    "#b2ffff",
    "#4997d0",
    "#de3163",
    "#ec3b83",
    "#007ba7",
    "#2a52be",
    "#a0785a",
    "#fad6a5",
    "#36454f",
    "#7fff00",
    "#de3163",
    "#ffb7c5",
    "#cd5c5c",
    "#d2691e",
    "#ffa700",
    "#98817b",
    "#e34234",
    "#d2691e",
    "#e4d00a",
    "#fbcce7",
    "#0047ab",
    "#d2691e",
    "#6f4e37",
    "#9bddff",
    "#002e63",
    "#8c92ac",
    "#b87333",
    "#996666",
    "#ff3800",
    "#ff7f50",
    "#f88379",
    "#ff4040",
    "#893f45",
    "#fbec5d",
    "#b31b1b",
    "#9aceeb",
    "#6495ed",
    "#fff8dc",
    "#fff8e7",
    "#ffbcd9",
    "#fffdd0",
    "#dc143c",
    "#990000",
    "#be0032",
    "#00ffff",
    "#ffff31",
    "#f0e130",
    "#00008b",
    "#654321",
    "#5d3954",
    "#a40000",
    "#08457e",
    "#986960",
    "#cd5b45",
    "#008b8b",
    "#536878",
    "#b8860b",
    "#a9a9a9",
    "#013220",
    "#1a2421",
    "#bdb76b",
    "#483c32",
    "#734f96",
    "#8b008b",
    "#003366",
    "#556b2f",
    "#ff8c00",
    "#9932cc",
    "#779ecb",
    "#03c03c",
    "#966fd6",
    "#c23b22",
    "#e75480",
    "#003399",
    "#872657",
    "#8b0000",
    "#e9967a",
    "#560319",
    "#8fbc8f",
    "#3c1414",
    "#483d8b",
    "#2f4f4f",
    "#177245",
    "#918151",
    "#ffa812",
    "#483c32",
    "#cc4e5c",
    "#00ced1",
    "#9400d3",
    "#00693e",
    "#555555",
    "#d70a53",
    "#a9203e",
    "#ef3038",
    "#e9692c",
    "#da3287",
    "#fad6a5",
    "#b94e48",
    "#704241",
    "#c154c1",
    "#004b49",
    "#9955bb",
    "#cc00cc",
    "#ffcba4",
    "#ff1493",
    "#ff9933",
    "#00bfff",
    "#1560bd",
    "#c19a6b",
    "#edc9af",
    "#696969",
    "#1e90ff",
    "#d71868",
    "#85bb65",
    "#967117",
    "#00009c",
    "#e1a95f",
    "#c2b280",
    "#614051",
    "#f0ead6",
    "#1034a6",
    "#7df9ff",
    "#ff003f",
    "#00ffff",
    "#00ff00",
    "#6f00ff",
    "#f4bbff",
    "#ccff00",
    "#bf00ff",
    "#3f00ff",
    "#8f00ff",
    "#ffff00",
    "#50c878",
    "#96c8a2",
    "#c19a6b",
    "#801818",
    "#ff00ff",
    "#b53389",
    "#f400a1",
    "#e5aa70",
    "#4d5d53",
    "#71bc78",
    "#4f7942",
    "#ff2800",
    "#6c541e",
    "#ce2029",
    "#b22222",
    "#e25822",
    "#fc8eac",
    "#f7e98e",
    "#eedc82",
    "#fffaf0",
    "#ffbf00",
    "#ff1493",
    "#ccff00",
    "#ff004f",
    "#228b22",
    "#a67b5b",
    "#0072bb",
    "#86608e",
    "#f64a8a",
    "#ff00ff",
    "#ff77ff",
    "#e48400",
    "#cc6666",
    "#dcdcdc",
    "#e49b0f",
    "#f8f8ff",
    "#b06500",
    "#6082b6",
    "#e6e8fa",
    "#ffd700",
    "#996515",
    "#fcc200",
    "#ffdf00",
    "#daa520",
    "#a8e4a0",
    "#808080",
    "#465945",
    "#00ff00",
    "#1164b4",
    "#adff2f",
    "#a99a86",
    "#00ff7f",
    "#663854",
    "#446ccf",
    "#5218fa",
    "#e9d66b",
    "#3fff00",
    "#c90016",
    "#da9100",
    "#808000",
    "#df73ff",
    "#f400a1",
    "#f0fff0",
    "#49796b",
    "#ff1dce",
    "#ff69b4",
    "#355e3b",
    "#fcf75e",
    "#b2ec5d",
    "#138808",
    "#cd5c5c",
    "#e3a857",
    "#4b0082",
    "#002fa7",
    "#ff4f00",
    "#5a4fcf",
    "#f4f0ec",
    "#009000",
    "#fffff0",
    "#00a86b",
    "#f8de7e",
    "#d73b3e",
    "#a50b5e",
    "#fada5e",
    "#bdda57",
    "#29ab87",
    "#e8000d",
    "#4cbb17",
    "#c3b091",
    "#087830",
    "#d6cadd",
    "#26619c",
    "#fefe22",
    "#a9ba9d",
    "#cf1020",
    "#e6e6fa",
    "#ccccff",
    "#fff0f5",
    "#c4c3d0",
    "#9457eb",
    "#ee82ee",
    "#e6e6fa",
    "#967bb6",
    "#fba0e3",
    "#7cfc00",
    "#fff700",
    "#fff44f",
    "#fffacd",
    "#bfff00",
    "#f56991",
    "#e68fac",
    "#fdd5b1",
    "#add8e6",
    "#b5651d",
    "#e66771",
    "#f08080",
    "#93ccea",
    "#e0ffff",
    "#f984ef",
    "#fafad2",
    "#d3d3d3",
    "#90ee90",
    "#f0e68c",
    "#b19cd9",
    "#ffb6c1",
    "#ffa07a",
    "#ff9999",
    "#20b2aa",
    "#87cefa",
    "#778899",
    "#b38b6d",
    "#ffffed",
    "#c8a2c8",
    "#bfff00",
    "#32cd32",
    "#195905",
    "#faf0e6",
    "#c19a6b",
    "#534b4f",
    "#e62020",
    "#18453b",
    "#ffbd88",
    "#ff00ff",
    "#aaf0d1",
    "#f8f4ff",
    "#c04000",
    "#fbec5d",
    "#6050dc",
    "#0bda51",
    "#979aaa",
    "#ff8243",
    "#74c365",
    "#800000",
    "#e0b0ff",
    "#915f6d",
    "#ef98aa",
    "#73c2fb",
    "#e5b73b",
    "#0067a5",
    "#66ddaa",
    "#0000cd",
    "#e2062c",
    "#af4035",
    "#f3e5ab",
    "#035096",
    "#1c352d",
    "#dda0dd",
    "#ba55d3",
    "#9370db",
    "#bb3385",
    "#3cb371",
    "#7b68ee",
    "#c9dc87",
    "#00fa9a",
    "#674c47",
    "#0054b4",
    "#48d1cc",
    "#c71585",
    "#fdbcb4",
    "#191970",
    "#004953",
    "#ffc40c",
    "#3eb489",
    "#f5fffa",
    "#98ff98",
    "#ffe4e1",
    "#faebd7",
    "#967117",
    "#73a9c2",
    "#ae0c00",
    "#addfad",
    "#30ba8f",
    "#997a8d",
    "#c54b8c",
    "#f2f3f4",
    "#ffdb58",
    "#21421e",
    "#f6adc6",
    "#2a8000",
    "#fada5e",
    "#ffdead",
    "#000080",
    "#ffa343",
    "#fe59c2",
    "#39ff14",
    "#a4dded",
    "#059033",
    "#0077be",
    "#cc7722",
    "#008000",
    "#cfb53b",
    "#fdf5e6",
    "#796878",
    "#673147",
    "#c08081",
    "#808000",
    "#6b8e23",
    "#bab86c",
    "#9ab973",
    "#0f0f0f",
    "#b784a7",
    "#ffa500",
    "#f8d568",
    "#ff9f00",
    "#ff4500",
    "#da70d6",
    "#654321",
    "#414a4c",
    "#ff6e4a",
    "#002147",
    "#1ca9c9",
    "#006600",
    "#273be2",
    "#682860",
    "#bcd4e6",
    "#afeeee",
    "#987654",
    "#af4035",
    "#9bc4e2",
    "#ddadaf",
    "#da8a67",
    "#abcdef",
    "#e6be8a",
    "#eee8aa",
    "#98fb98",
    "#dcd0ff",
    "#f984e5",
    "#fadadd",
    "#dda0dd",
    "#db7093",
    "#96ded1",
    "#c9c0bb",
    "#ecebbd",
    "#bc987e",
    "#db7093",
    "#78184a",
    "#ffefd5",
    "#50c878",
    "#aec6cf",
    "#836953",
    "#cfcfc4",
    "#77dd77",
    "#f49ac2",
    "#ffb347",
    "#ffd1dc",
    "#b39eb5",
    "#ff6961",
    "#cb99c9",
    "#fdfd96",
    "#800080",
    "#536878",
    "#ffe5b4",
    "#ffdab9",
    "#fadfad",
    "#d1e231",
    "#eae0c8",
    "#88d8c0",
    "#e6e200",
    "#ccccff",
    "#1c39bb",
    "#32127a",
    "#d99058",
    "#f77fbe",
    "#701c1c",
    "#cc3333",
    "#fe28a2",
    "#df00ff",
    "#000f89",
    "#123524",
    "#fddde6",
    "#01796f",
    "#ffc0cb",
    "#fc74fd",
    "#f78fa7",
    "#e7accf",
    "#93c572",
    "#e5e4e2",
    "#dda0dd",
    "#ff5a36",
    "#b0e0e6",
    "#ff8f00",
    "#003153",
    "#df00ff",
    "#cc8899",
    "#ff7518",
    "#800080",
    "#69359c",
    "#9d81ba",
    "#9678b6",
    "#fe4eda",
    "#50404d",
    "#5d8aa8",
    "#ff355e",
    "#e30b5d",
    "#915f6d",
    "#e25098",
    "#b3446c",
    "#d68a59",
    "#ff33cc",
    "#e3256b",
    "#ff0000",
    "#ff5349",
    "#a52a2a",
    "#c71585",
    "#004040",
    "#d70040",
    "#0892d0",
    "#b666d2",
    "#b03060",
    "#414833",
    "#1fcecb",
    "#ff007f",
    "#f9429e",
    "#674846",
    "#b76e79",
    "#e32636",
    "#ff66cc",
    "#aa98a9",
    "#905d5d",
    "#ab4e52",
    "#65000b",
    "#d40000",
    "#bc8f8f",
    "#0038a8",
    "#4169e1",
    "#ca2c92",
    "#7851a9",
    "#e0115f",
    "#ff0028",
    "#bb6528",
    "#e18e96",
    "#a81c07",
    "#80461b",
    "#b7410e",
    "#00563f",
    "#8b4513",
    "#ff6700",
    "#f4c430",
    "#23297a",
    "#ff8c69",
    "#ff91a4",
    "#c2b280",
    "#967117",
    "#ecd540",
    "#f4a460",
    "#967117",
    "#507d2a",
    "#0f52ba",
    "#cba135",
    "#ff2400",
    "#ffd800",
    "#76ff7a",
    "#006994",
    "#2e8b57",
    "#321414",
    "#fff5ee",
    "#ffba00",
    "#704214",
    "#8a795d",
    "#45cea2",
    "#009e60",
    "#fc0fc0",
    "#882d17",
    "#c0c0c0",
    "#cb410b",
    "#007474",
    "#87ceeb",
    "#cf71af",
    "#6a5acd",
    "#708090",
    "#003399",
    "#933d41",
    "#100c08",
    "#fffafa",
    "#0fc0fc",
    "#a7fc00",
    "#00ff7f",
    "#4682b4",
    "#fada5e",
    "#990000",
    "#008080",
    "#e4d96f",
    "#ffcc33",
    "#fad6a5",
    "#fd5e53",
    "#d2b48c",
    "#f94d00",
    "#f28500",
    "#ffcc00",
    "#483c32",
    "#8b8589",
    "#cd5700",
    "#d0f0c0",
    "#f4c2c2",
    "#008080",
    "#367588",
    "#006d5b",
    "#e2725b",
    "#d8bfd8",
    "#de6fa1",
    "#fc89ac",
    "#0abab5",
    "#e08d3c",
    "#dbd7d2",
    "#eee600",
    "#ff6347",
    "#746cc0",
    "#ffc87c",
    "#fd0e35",
    "#808080",
    "#00755e",
    "#0073cf",
    "#417dc1",
    "#deaa88",
    "#b57281",
    "#30d5c8",
    "#00ffef",
    "#a0d6b4",
    "#66424d",
    "#8a496b",
    "#66023c",
    "#0033aa",
    "#d9004c",
    "#536895",
    "#ffb300",
    "#3cd070",
    "#014421",
    "#7b1113",
    "#990000",
    "#ffcc00",
    "#8878c3",
    "#ff6fff",
    "#120a8f",
    "#4166f5",
    "#635147",
    "#5b92e5",
    "#b78727",
    "#ffff66",
    "#ae2029",
    "#e1ad21",
    "#d3003f",
    "#f3e5ab",
    "#c5b358",
    "#c80815",
    "#43b3ae",
    "#e34234",
    "#a020f0",
    "#ee82ee",
    "#324ab2",
    "#f75394",
    "#40826d",
    "#922724",
    "#9f1d35",
    "#da1d81",
    "#ffa089",
    "#9f00ff",
    "#004242",
    "#00ffff",
    "#645452",
    "#f5deb3",
    "#ffffff",
    "#f5f5f5",
    "#ff43a4",
    "#fc6c85",
    "#a2add0",
    "#722f37",
    "#c9a0dc",
    "#738678",
    "#0f4d92",
    "#ffff00",
    "#ffae42",
    "#9acd32",
    "#0014a8",
    "#2c1608",
  ])
  .mode("lch")
  .colors(750);
const isSmallLaptop = window.innerHeight < 800;
const mapIniital = {
  anchor: [17.132582, 96.141762],
  startZoom: 6.5,
  minZoom: 2,
  maxZoom: 16,
  bounds: null,
  maxBounds: [
    [180, -180],
    [-180, 180],
  ],
};
const space3dInitial = {};

const initial = {
  /*
   * The Domain or 'domain' of this state refers to the tree of data
   *  available for render and display.
   * Selections and filters in the 'app' subtree will operate the domain
   *   in mapStateToProps of the Dashboard, and determine which items
   *   in the domain will get rendered by React
   */
  domain: {
    events: [],
    categories: [],
    associations: [],
    sources: {},
    sites: [],
    shapes: [],
    regions: [],
    notifications: [],
  },

  /*
   * The 'app' subtree of this state determines the data and information to be
   *   displayed.
   * It may refer to those the user interacts with, by selecting,
   *   filtering and so on, which ultimately operate on the data to be displayed.
   * Additionally, some of the 'app' flags are determined by the config file
   *   or by the characteristics of the client, browser, etc.
   */
  app: {
    debug: true,
    errors: {
      source: false,
    },
    highlighted: null,
    selected: [],
    source: null,
    associations: {
      coloringSet: [],
      filters: [],
      narrative: null,
      categories: [],
      views: {
        events: true,
        routes: false,
        sites: true,
      },
    },
    shapes: [],
    isMobile: /Mobi/.test(navigator.userAgent),
    language: "en-US",
    cluster: {
      radius: 30,
      minZoom: 2,
      maxZoom: 16,
    },
    timeline: {
      dimensions: {
        height: isSmallLaptop ? 170 : 250,
        width: 0,
        marginLeft: 70,
        marginTop: isSmallLaptop ? 5 : 10, // the padding used for the day/month labels inside the timeline
        marginBottom: 60,
        contentHeight: isSmallLaptop ? 160 : 200,
        width_controls: 100,
      },
      range: [new Date(2021, 1, 1, 12), new Date(2022, 11, 31, 12)],
      rangeLimits: [new Date(1, 1, 1, 1), new Date()],
      zoomLevels: copy[language].timeline.zoomLevels || [
        { label: "3 years", duration: 1577847 },
        { label: "2 years", duration: 1051200 },
        { label: "1 year", duration: 525949 },
        { label: "3 months", duration: 129600 },
        { label: "3 days", duration: 4320 },
        { label: "12 hours", duration: 720 },
        { label: "1 hour", duration: 60 },
      ],
    },
    flags: {
      isFetchingDomain: false,
      isFetchingSources: false,
      isCover: true,
      isCardstack: true,
      isInfopopup: true,
      isIntropopup: false,
      isShowingSites: false,
    },
    cover: {
      title: "project title",
      description:
        "A description of the project goes here.\n\nThis description may contain markdown.\n\n# This is a large title, for example.\n\n## Whereas this is a slightly smaller title.\n\nCheck out docs/custom-covers.md in the [Timemap GitHub repo](https://github.com/forensic-architecture/timemap) for more information around how to specify custom covers.",
      exploreButton: "EXPLORE",
    },
    toolbar: {
      panels: {
        categories: {
          default: {
            icon: DEFAULT_TAB_ICONS.CATEGORY,
            label: copy[language].toolbar.categories_label,
            title: copy[language].toolbar.explore_by_category__title,
            description:
              copy[language].toolbar.explore_by_category__description,
          },
        },
        filters: {
          icon: DEFAULT_TAB_ICONS.FILTER,
          label: copy[language].toolbar.filters_label,
          title: copy[language].toolbar.explore_by_filter__title,
          description: copy[language].toolbar.explore_by_filter__description,
        },
        narratives: {
          icon: DEFAULT_TAB_ICONS.NARRATIVE,
          label: copy[language].toolbar.narratives_label,
          title: copy[language].toolbar.explore_by_narrative__title,
          description: copy[language].toolbar.explore_by_narrative__description,
        },
        shapes: {
          icon: DEFAULT_TAB_ICONS.SHAPE,
          label: copy[language].toolbar.shapes_label,
          title: copy[language].toolbar.explore_by_shape__title,
          description: copy[language].toolbar.explore_by_shape__description,
        },
      },
    },
    loading: false,
  },

  /*
   * The 'ui' subtree of this state refers the state of the cosmetic
   *   elements of the application, such as color palettes of clusters
   *   as well as dom elements to attach SVG
   */
  ui: {
    tiles: "openstreetmap", // ['openstreetmap', 'streets', 'satellite']
    style: {
      categories: {
        default: global.fallbackEventColor,
      },
      narratives: {
        default: {
          opacity: 0.9,
          stroke: global.fallbackEventColor,
          strokeWidth: 3,
        },
      },
      regions: {
        default: {
          stroke: "blue",
          strokeWidth: 3,
          opacity: 0.9,
        },
      },
      clusters: {
        radial: false,
      },
    },
    card: {
      layout: {
        template: "basic",
      },
    },
    coloring: {
      maxNumOfColors: 750,
      colors: gcolors,
    },
    dom: {
      timeline: "timeline",
      timeslider: "timeslider",
      map: "map",
    },
    eventRadius: 8,
  },

  features: {
    USE_COVER: false,
    USE_ASSOCIATIONS: false,
    USE_SITES: false,
    USE_SOURCES: false,
    USE_REGIONS: false,
    GRAPH_NONLOCATED: false,
    HIGHLIGHT_GROUPS: false,
  },
};

let appStore;
if (process.env.store) {
  appStore = mergeDeepLeft(process.env.store, initial);
} else {
  appStore = initial;
}

// NB: config.js dates get implicitly converted to strings in mergeDeepLeft
appStore.app.timeline.range[0] = new Date(appStore.app.timeline.range[0]);
appStore.app.timeline.range[1] = new Date(appStore.app.timeline.range[1]);
appStore.app.flags.isIntropopup = !!appStore.app.intro;

if ("map" in appStore.app) {
  appStore.app.map = mergeDeepLeft(appStore.app.map, mapIniital);
}

if ("space3d" in appStore.app) {
  appStore.app.space3d = mergeDeepLeft(appStore.app.space3d, space3dInitial);
}

export default appStore;
